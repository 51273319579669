<template>
  <router-view />
</template>

<script>
import '@/modules/questionnaire/assets/scss/styles.scss';

import { mapMutations } from 'vuex';

import { types as dashTypes } from '@/modules/dashboard/store/types';

export default {
  name: 'AppQuestionnaire',
  mounted() {
    this.setIsDashboardLocked(true);
  },
  methods: {
    ...mapMutations({
      setIsDashboardLocked: dashTypes.mutations.SET_IS_DASHBOARD_LOCKED
    })
  }
};
</script>
